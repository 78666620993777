// const BASE_URL = "https://localhost:4000/api/v1";
const BASE_URL = "https://swissapi.alleshealth.com/v1/admin";

// AUTH ENDPOINTS
export const endpoints = {
  LOGIN_API: BASE_URL + "/adminLogin",
};

// PATIENTS ENDPOINTS
export const patientEndpoints = {
  GET_ALL_PATIENTS_API: BASE_URL + "/userDetails",
};

// CLINIC ENDPOINTS
export const clinicEndpoints = {
  GET_ALL_REFEERED_CLINIC_API: BASE_URL + "/referredClinicsDetails",
};
