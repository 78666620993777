import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OpenRoute from "./components/core/Auth/OpenRoute";
import PrivateRoute from "./components/core/Auth/PrivateRoute";
import Login from "./pages/Login";

import Dashboard from "./pages/Dashboard";
import Admin from "./components/core/Dashboard/AdminDashboard/Admin";
import Navbar from "./components/common/Navbar";
import MyProfile from "./components/core/Dashboard/MyProfile";
import PatientManagement from "./components/core/PatientManagement/PatientManagement";
import ClinicManagement from "./components/core/ClinicManagement/ClinicManagement";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.profile);
  return (
    <div className="w-screen min-h-screen bg-white flex flex-col font-poppins">
      {user && <Navbar />}
      <Routes>
        <Route
          path="/"
          element={
            <OpenRoute>
              <Login />
            </OpenRoute>
          }
        />
        <Route
          path="login"
          element={
            <OpenRoute>
              <Login />
            </OpenRoute>
          }
        />

        <Route
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route path="dashboard/admin-dashboard" element={<Admin />} />
          <Route
            path="dashboard/user-management"
            element={<PatientManagement />}
          />
          <Route path="dashboard/clinic-management" element={<ClinicManagement />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
