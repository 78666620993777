const AWS = require("aws-sdk");
export function getBucketURL(objectKey) {
  AWS.config.update({
    accessKeyId: "AKIAZC3LSAMSGZ5OXHTK",
    secretAccessKey: "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",
    region: "eu-central-2",
  });

  const s3 = new AWS.S3();

  const params = {
    Bucket: "alleshealth-swiss-prod-bucket",
    Key: objectKey,
  };

  return new Promise((resolve, reject) => {
    s3.getSignedUrl("getObject", params, function (err, url) {
      if (err) {
        reject(err);
      } else {
        resolve(url);
      }
    });
  });
}
