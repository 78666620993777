import { ACCOUNT_TYPE } from "../utils/constants";
export const sidebarLinks = [
  {
    id: 1,
    name: "Dashboard",
    path: "/dashboard/admin-dashboard",
    icon: "RxDashboard",
  },
  {
    id: 2,
    name: "Patient Management",
    path: "/dashboard/user-management",
    icon: "RxDashboard",
  },
  {
    id: 3,
    name: "Clinic Management",
    path: "/dashboard/clinic-management",
    icon: "RxDashboard",
  },
];
