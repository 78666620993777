import { toast } from "react-hot-toast";
import { setLoading, setToken } from "../../slices/authSlice";
import { setUser } from "../../slices/profileSlice";
import { apiConnector } from "../apiconnector";
import { endpoints } from "../apis";

const { LOGIN_API } = endpoints;

export function login(Email, Password, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", LOGIN_API, {
        Email,
        Password,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      toast.success("Login Successful");
      dispatch(setToken(response.data.user.AccessToken));
      const userImage = response.data?.user?.Image
        ? response.data.user.Image
        : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.FirstName} ${response.data.user.LastName}`;
      dispatch(setUser({ ...response.data.user, Image: userImage }));

      localStorage.setItem(
        "token",
        JSON.stringify(response.data.user.AccessToken)
      );
      localStorage.setItem("user", JSON.stringify(response.data.user));
      navigate("/dashboard/admin-dashboard");
    } catch (error) {
      console.log("LOGIN API ERROR............", error);
      toast.error("Login Failed");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

export function logout(navigate) {
  return (dispatch) => {
    dispatch(setToken(null));
    dispatch(setUser(null));
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    toast.success("Logged Out");
    navigate("/");
  };
}
